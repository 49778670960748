<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Project Settings</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form>
          <v-text-field
            v-model='settings.advisersCount'
            label='Active Advisers Count'
            type='number'
            required
            :readonly='!isAdmin'
          ></v-text-field>
          <template v-if='isAdmin'>
            <v-text-field
              v-model='settings.fixed_rate'
              label='Default Fixed Rate for New Application'
              type='number'
              required
            ></v-text-field>
            <v-text-field
              v-model='settings.stress_rate'
              label='Default Stress Rate for New Application'
              type='number'
              required
            ></v-text-field>
            <v-text-field
              v-model='settings.terms'
              label='Default Terms for New Application'
              type='number'
              required
            ></v-text-field>
            <v-btn color='primary' @click='saveSettings' :loading='saving'> SAVE </v-btn>
          </template>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  setSettings,
  getSettings,
  getAdminRole,
} from '@/services/firebase';

import { SETTINGS } from '@/models/helpers/consts';

export default {
  name: 'Settings',

  data() {
    return {
      saving: false,
      settings: {},
      isAdmin: null,
    };
  },

  methods: {
    async saveSettings() {
      this.saving = true;

      await setSettings(this.settings);

      this.saving = false;
    },
  },

  async mounted() {
    try {
      this.isAdmin = await getAdminRole();
      const { settings } = await getSettings();
      this.settings = settings;
    } catch (error) {
      await setSettings(SETTINGS);
      this.settings = SETTINGS;
    }
  },
};
</script>
