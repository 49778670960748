var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', [_c('h2', [_vm._v("Project Settings")])])], 1), _c('v-row', [_c('v-col', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "label": "Active Advisers Count",
      "type": "number",
      "required": "",
      "readonly": !_vm.isAdmin
    },
    model: {
      value: _vm.settings.advisersCount,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "advisersCount", $$v);
      },
      expression: "settings.advisersCount"
    }
  }), _vm.isAdmin ? [_c('v-text-field', {
    attrs: {
      "label": "Default Fixed Rate for New Application",
      "type": "number",
      "required": ""
    },
    model: {
      value: _vm.settings.fixed_rate,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "fixed_rate", $$v);
      },
      expression: "settings.fixed_rate"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Default Stress Rate for New Application",
      "type": "number",
      "required": ""
    },
    model: {
      value: _vm.settings.stress_rate,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "stress_rate", $$v);
      },
      expression: "settings.stress_rate"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Default Terms for New Application",
      "type": "number",
      "required": ""
    },
    model: {
      value: _vm.settings.terms,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "terms", $$v);
      },
      expression: "settings.terms"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.saveSettings
    }
  }, [_vm._v(" SAVE ")])] : _vm._e()], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }